<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Configure os usuários do sistema e caixa, configure permissões, acesso as lojas e redefina a senhas.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-md mb-3">
							<label><i class="far fa-user color-theme font-11 me-1"></i> Nome/CPF/RG</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarUsuarios" placeholder="Busque por nome do usuário, CPF ou RG" />
                  </div>
						<div class="col-md-4 col-xl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarUsuarios"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Configuração | Editar usuários')">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)" v-if="dadosUsuario.maxUsuarios > pesquisa.totalUsuarios">
										<i class="far fa-plus font-13 me-1"></i> Adicionar
									</button>
									<button type="button" class="btn btn-outline-primary w-100" @click="irParaPacotes" v-else><i class="far fa-star font-13 me-1"></i> Fazer upgrade</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<usuario v-for="(usuario, index) in pesquisa.retorno.resultado" :key="index" :usuario="usuario" :index="index" :tela="'USUARIOS'"
							@editar="abrirModal($event)" />
					</div>
				</div>
			</div>
      </div>

      <!-- modalUsuario -->
      <div class="modal fade" id="modalUsuario" tabindex="-1" aria-labelledby="modalUsuarioLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalUsuarioLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} usuário {{ modal.id == null ? '' : ('('+ modal.nomeCompleto +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-usuario-tab" data-bs-toggle="pill" data-bs-target="#pills-usuario" type="button" role="tab" aria-controls="pills-usuario" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-sm-inline"> Principal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-permissoes-tab" data-bs-toggle="pill" data-bs-target="#pills-permissoes" type="button" role="tab" aria-controls="pills-permissoes" aria-selected="false">
											<i class="far fa-lock font-12"></i><span class="ms-1 d-none d-sm-inline"> Permissões</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-usuario" role="tabpanel" aria-labelledby="pills-usuario-tab">
								<div class="accordion" id="accordionExample">
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingOne">
											<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
												<i class="fal fa-home"></i> Informações básicas
											</button>
										</h2>
										<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-user color-theme font-12 me-1"></i> Nome *</label>
														<input type="text" class="form-control" v-model="modal.nomeCompleto" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-envelope color-theme font-11 me-1"></i> Email *</label>
														<input type="text" class="form-control" v-model="modal.email" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-address-card color-theme font-11 me-1"></i> CPF *</label>
														<input type="text" class="form-control" v-model="modal.cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-address-card color-theme font-11 me-1"></i> RG</label>
														<input type="text" class="form-control" v-model="modal.rg" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3 mb-sm-0">
														<label><i class="far fa-calendar color-theme font-11 me-1"></i> Data nascimento</label>
														<input type="text" class="form-control" v-model="modal.dataNascimento" v-mask="'##/##/####'" />
													</div>
													<div class="col-sm-6 align-self-end" v-if="modal.id">
														<label class="cursor-pointer my-12 ms-sm-2" data-bs-toggle="modal" data-bs-target="#modalSenha" 
															@click="config.tipo = 'Senha'; config.cadastrado = modal.senha != null;">
															<i class="far fa-long-arrow-right me-1"></i> {{ modal.senha != null ? 'Redefinir' : 'Definir' }} senha de acesso
														</label>
													</div>
													<div class="col-sm-6" v-else>
														<label><i class="far fa-lock color-theme font-11 me-1"></i> Senha de acesso</label>
														<input type="text" class="form-control key" v-model="modal.senha" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingTwo">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
												<i class="fal fa-map-marker-alt me-3"></i>Endereço
											</button>
										</h2>
										<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-4 mb-3">
														<label><i class="far fa-location-arrow color-theme font-11 me-1"></i> CEP</label>
														<div class="input-group">
															<input type="text" class="form-control" v-model="modal.endereco.cep" v-mask="'##########'" @keyup.enter="buscarCEP" />
															<a href="javascript:;" class="input-group-text font-13" @click="buscarCEP">
																<i class="fas fa-search"></i>
															</a>
														</div>
													</div>
													<div class="col-sm-8 mb-3">
														<label><i class="far fa-home color-theme font-11 me-1"></i> Rua</label>
														<input type="text" class="form-control" v-model="modal.endereco.rua" maxlength="200" />
													</div>
													<div class="col-sm-4 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modal.endereco.numero" maxlength="200" />
													</div>
													<div class="col-sm-8 mb-3">
														<label><i class="far fa-road color-theme font-11 me-1"></i> Bairro</label>
														<input type="text" class="form-control" v-model="modal.endereco.bairro" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cidade</label>
														<input type="text" class="form-control" v-model="modal.endereco.cidade" maxlength="200" />
													</div>
													<div class="col-sm-6 mb-3">
														<label><i class="far fa-map-marker-alt color-theme font-11 me-1"></i> Estado</label>
														<v-select name="Desktop" :options="estados" v-model="modal.endereco.estado" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modal.endereco.estado">
															<option v-for="(option, index) in estados" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cód. Município</label>
														<input type="text" class="form-control" v-model="modal.endereco.codMunicipio" maxlength="200" />
													</div>
													<div class="col-sm-8">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Complemento</label>
														<input type="text" class="form-control" v-model="modal.endereco.complemento" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header" id="headingThree">
											<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
												<i class="fal fa-phone-alt"></i> Telefone
											</button>
										</h2>
										<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
											<div class="accordion-body">
												<div class="row">
													<div class="col-sm-3 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> DDD</label>
														<input type="text" class="form-control" v-model="modal.telefone.ddd" v-mask="['(##)', '(###)']" />
													</div>
													<div class="col-sm-5 mb-3">
														<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
														<input type="text" class="form-control" v-model="modal.telefone.telefone" v-mask="['####-####', '#####-####']" />
													</div>
													<div class="col-sm-4 mb-3 mb-sm-0">
														<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
														<v-select name="Desktop" :options="['Fixo', 'Celular']" v-model="modal.telefone.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
														<select name="Mobile" class="form-control" v-model="modal.telefone.tipo">
															<option v-for="(option, index) in ['Fixo', 'Celular']" :key="index" :value="option">{{ option }}</option>
														</select>
													</div>
													<div class="col-12">
														<label><i class="far fa-user-alt color-theme font-11 me-1"></i> Nome contato</label>
														<input type="text" class="form-control" v-model="modal.telefone.nomeContato" maxlength="200" />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="tab-pane fade" id="pills-permissoes" role="tabpanel" aria-labelledby="pills-permissoes-tab">
								<div class="row">
									<div class="col-lg-6 ps-lg-0">
										<div class="card mb-2">
											<div class="card-body p-12">
												<div class="row">
													<div class="w-max-content mb-2 mb-sm-3 mb-lg-2 mb-xl-3">
														<h6 class="mb-0 mt-1 font-15"><i class="far fa-browser color-theme font-13 me-2"></i>Módulos</h6>
													</div>
													<div class="col-sm mb-3">
														<div class="form-check mt-1 w-max-content ms-sm-auto ms-lg-0 ms-xl-auto">
															<input class="form-check-input" type="checkbox" id="checkAdmin" :checked="modal.admin" v-model="modal.admin" @click="toggleAllModulos">
															<label class="form-check-label ps-1 cursor-pointer w-max-content" for="checkAdmin">Administrador com acesso a todas as funções</label>
														</div>
													</div>

													<modulo v-for="(modulo, index) in modal.modulos" :key="index" :index="index" :modulo="modulo" @toggle="toggleModulo($event)" />

													<div class="col-12 my-5 text-center" v-if="modal.modulos.length == 0">
														<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
														<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum módulo encontrado</h4>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-lg-6 pe-lg-0">
										<div class="card mb-2">
											<div class="card-body p-12">
												<div class="row">
													<div class="w-max-content mb-2 mb-sm-3 mb-lg-2 mb-xl-3">
														<h6 class="mb-0 mt-1 font-15"><i class="far fa-store color-theme font-13 me-2"></i>Lojas</h6>
													</div>
													<div class="col-sm mb-3" v-if="modal.id">
														<label class="cursor-pointer w-max-content ms-sm-auto mb-0 mt-1" data-bs-toggle="modal" data-bs-target="#modalSenha" 
															@click="config.tipo = 'Supervisor'; config.cadastrado = modal.codigoSupervisor != null;">
															<i class="far fa-long-arrow-right me-1"></i> {{ modal.codigoSupervisor != null ? 'Redefinir' : 'Definir' }} código de supervisor
														</label>
													</div>

													<loja v-for="(loja, index) in modal.lojas" :key="index" :index="index" :loja="loja" />

													<div class="col-12 my-5 text-center" v-if="modal.lojas.length == 0">
														<i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
														<p class="font-13 mt-4 mb-0 text-secondary">Nenhuma loja encontrada</p>
														<h4 class="font-12 mt-2 mb-0 text-secondary text-uppercase">Solicite permissão ou utilize a tela "Lojas" para adicionar</h4>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarUsuario">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- modalSenha -->
      <div class="modal fade" id="modalSenha" tabindex="-1" aria-labelledby="modalSenhaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalSenhaLabel">{{ config.cadastrado ? 'Redefinir' : 'Definir' }} senha/código {{ String(config.tipo).toLowerCase() }}</h5>
                  <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#modalUsuario" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card">
							<div class="card-body p-12">
								<div class="col-12 mb-3" v-if="config.cadastrado">
									<label><i class="far fa-barcode color-theme font-12 me-1"></i> Código atual *</label>
									<input type="text" class="form-control key" v-model="config.atual" maxlength="200" />
								</div>
								<div class="col-12">
									<label><i class="far fa-barcode color-theme font-12 me-1"></i> Código novo *</label>
									<input type="text" class="form-control key" v-model="config.novo" maxlength="200" />
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarSenha">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalUsuario">Fechar</button>
               </div>
				</div>
			</div>
		</div>
   </div>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import loja from '@/components/modelos/LojaCheck.vue'
import usuario from '@/components/configuracoes/usuarios/Usuario.vue'
import modulo from '@/components/configuracoes/usuarios/CheckModulo.vue'

export default {
	name: 'Usuarios',
	data: function () {
		return {
			pesquisa: {'nome': '', 'totalUsuarios': 0, 'retorno': {'nome': null, 'resultado': []}},
			modal: {
				'id': null, 'cpf': null, 'rg': null, 'dataNascimento': null, 'email': null, 'nomeCompleto': null, 'senha': null,
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''}, 'admin': false,
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}, 'lojas': [], 'modulos': [], 'codigoSupervisor': null
			},
			config: {'novo': null, 'atual': null, 'id': null, 'cadastrado': false, 'tipo': null}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         estados: state => state.estados,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
			this.$store.dispatch('getCountUsuarios').then((data) => this.pesquisa.totalUsuarios = data)
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		usuario, modulo, loja
	},
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Usuários',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'nomeCompleto', 'email', 'rg', 'cpf', 'dataNascimento'], 
				'lista': null
			})
      },
		abrirModal : function (usuario) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
			
			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoes/getUsuario',
				params: {
					email: usuario == null ? null : usuario.email
				}
			}).then(response => {
				$('#modalUsuario').modal('show')
				
				this.config = {'tipo': null, 'id': response.data.id, 'cadastrado': false, 'atual': null, 'novo': null}
				this.modal = {
					'id': response.data.id, 
					'cpf': response.data.cpf, 
					'rg': response.data.rg, 
					'dataNascimento': response.data.dataNascimento == null ? null : String(response.data.dataNascimento.dayOfMonth).padStart(2, '0') +'/'+ String(response.data.dataNascimento.monthValue).padStart(2, '0') +'/'+ response.data.dataNascimento.year, 
					'email': response.data.email, 
					'nomeCompleto': response.data.nomeCompleto, 
					'senha': response.data.senha, 
					'codigoSupervisor': response.data.codigoSupervisor, 
					'endereco': response.data.endereco, 
					'telefone': response.data.telefone,
					'lojas': JSON.parse(JSON.stringify(response.data.lojas)),
					'modulos': JSON.parse(JSON.stringify(response.data.modulos)), 
					'admin': false
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		irParaPacotes : function () {
			router.push('/minhaConta/'+ this.tabs.length)
		},
		buscarUsuarios : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoes/searchUsuarios',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		toggleModulo : function (idModulo) {
         this.modal.modulos.forEach(modulo => {
            if (modulo.id == idModulo) {
               modulo.habilitado = !modulo.habilitado

               modulo.roles.forEach(role => {
                  role.habilitado = modulo.habilitado
               });
            }
         });
      },
		toggleAllModulos : function () {
         this.modal.modulos.forEach(modulo => {
				modulo.habilitado = !this.modal.admin

				modulo.roles.forEach(role => {
					role.habilitado = !this.modal.admin
				});
         });
      },
		buscarCEP : function () {
         if (String(this.modal.endereco.cep).trim().length < 8) {
            return;
         }

         this.$store.dispatch('buscarCEP', this.modal.endereco.cep).then((data) => {
				this.modal.endereco.rua = data.logradouro
				this.modal.endereco.complemento = data.complemento
				this.modal.endereco.bairro = data.bairro
				this.modal.endereco.cidade = data.localidade
				this.modal.endereco.estado = data.uf
				this.modal.endereco.codMunicipio = data.ibge
			})
      },
		salvarUsuario : function () {
			if (this.modal.cpf == null || String(this.modal.cpf).trim().length == 0 ||
				this.modal.nomeCompleto == null || String(this.modal.nomeCompleto).trim().length == 0 ||
				this.modal.email == null || String(this.modal.email).trim().length == 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoes/saveUsuario',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalUsuario').modal('hide')
				
				this.$store.dispatch('getCountUsuarios').then((data) => this.pesquisa.totalUsuarios = data)
				this.buscarUsuarios()

				this.$toast.fire({
					icon: 'success',
					title: 'Usuário salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Limite de usuários excedido!'
						});
					} else if (error.response.status == 409) {
						this.$toast.fire({
							icon: 'error',
							title: 'Email já cadastrado!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarSenha : function () {
			if ((this.config.cadastrado && (this.config.atual == null || String(this.config.atual).trim().length == 0)) || 
				this.config.novo == null || String(this.config.novo).trim().length == 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('salvarSenhaUsuario', this.config).then(() => {
				$('#modalSenha').modal('hide')

				switch (this.config.tipo) {
					case 'Senha':
						this.modal.senha = JSON.parse(JSON.stringify(this.config.novo))
					break;
					case 'Supervisor':
						this.modal.codigoSupervisor = JSON.parse(JSON.stringify(this.config.novo))
					break;
				}

				this.config = {'novo': null, 'atual': null, 'id': this.config.id, 'cadastrado': false, 'tipo': null}
				$('#modalUsuario').modal('show')
			})
		}
	},
	mounted() {
		this.$store.dispatch('getCountUsuarios').then((data) => this.pesquisa.totalUsuarios = data)
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>